
import { defineComponent } from "vue";

export default defineComponent({
    name: "choose-app",
    methods: {},
    setup() {
        const originUrl = window.location.origin;
        const favicon = "/media/logos/favicon-194x194.png";
        const logo = "/media/logos/new-login-logo.svg";
        const search = new URLSearchParams(window.location.search);
        const searchValue = `?email=${search.get("email")}&shop=${search.get(
            "shop"
        )}&shopName=${search.get("shopName")}&accessToken=${search.get(
            "accessToken"
        )}&xff=${search.get("xff")}`;
        const moConnectURL = `https://api-connect.multiorders.com/shopify/install-from-old-mo${searchValue}`;
        const loginURL = `/auth/login${searchValue}`;

        return {
            originUrl,
            moConnectURL,
            loginURL,
            logo,
            favicon,
        };
    },
});
